import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location }) => (
  <Layout location={location}>
    <SEO
      title="404 Not Found"
      description="404 Not Found."
      pathname="/404"
      article
    />
    <div
      css={css`
        background-color: var(--post-background-color);
        padding: 50px;
        margin-bottom: 150px;
      `}
    >
      <h1>404 Not Found.</h1>
      <p>指定された記事が見つかりませんでした。</p>
      <Link to="/">トップページへ</Link>
    </div>
  </Layout>
)
